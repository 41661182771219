import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';

import Layout from '../components/Layout';
import CategoryProjectGridItem from '../components/CategoryProjectGridItem';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 3.2rem 5%;
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    padding: 3.2rem 7.77rem;
  }
  h1 {
    ${props => props.theme.font.h2},
    margin-bottom: 3.05rem;
  }
  .project {
    color: ${props => props.theme.colors.white};
    min-height: calc(100vh - 8rem);
    h3,
    h4 {
      margin-bottom: 0.2rem;
      color: ${props => props.theme.colors.white};
    }
  }
`;

const ProjectItems = styled(Flex)`
  font-size: 1rem;
  flex-wrap: wrap;
  /* align-items:center; */
  justify-content: space-between;
  button {
    background-color: ${props => props.theme.colors.black};
    a {
      &,
      &:visited,
      &:link {
        color: ${props => props.theme.colors.white};
      }
      &:hover {
        color: ${props => props.theme.colors.greyLight};
      }
    }
  }
`;

const TagAllProjects = ({ pageContext, data: { projects } }) => {
  const filterContent = tag => {
    allowedTag = tag.replace(' ', '-');
    // console.log('filter content', allowedTag);
    // could probably query selector all right here
    const divsToHide = document.getElementsByClassName('category-project-item');
    for (let i = 0; i < divsToHide.length; i++) {
      const item = divsToHide[i];
      if (item.classList.contains(allowedTag)) {
        item.style.visibility = 'visible';
        item.style.display = 'block';
      } else {
        item.style.visibility = 'hidden';
        item.style.display = 'none';
      }
    }
  };

  let allowedTag = 'work';
  let filters = null;
  let tags = [];

  if (pageContext.slugifiedTag === 'work') {
    projects.edges.map(edge => {
      tags = tags.concat(edge.node.tags.filter(item => tags.indexOf(item) < 0));
    });

    // tags = tags.filter(tag => tag !== 'work');
    filters = tags.map((tag, idx) => (
      <button key={idx} onClick={() => filterContent(tag)}>
        {tag}
      </button>
    ));
  }
  return (
    <Layout>
      <Wrapper p={4} mb={[4, 4, 7]} mx="auto" justifyContent="space-between" flexWrap="wrap">
        {filters}
        <ProjectItems>
          {projects.edges.map(c => (
            <CategoryProjectGridItem
              key={c.node.id}
              slugifiedTag={pageContext.slugifiedTag}
              uid={c.node.uid}
              tags={c.node.tags}
              data={c.node.data}
            />
          ))}
        </ProjectItems>
      </Wrapper>
    </Layout>
  );
};

export default TagAllProjects;

TagAllProjects.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    template: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query TagAllProjectsQuery($tag: [String]) {
    projects: allPrismicProject(filter: { tags: { in: $tag, nin: "unpublished" } }, sort: { fields: [last_publication_date], order: DESC }) {
      edges {
        node {
          id
          uid
          slugs
          tags
          data {
            project_name
            client_name {
              text
            }
            reference_image {
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              url
            }
          }
        }
      }
    }
  }
`;
